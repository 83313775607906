.button {
  margin-top: 10px;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 70%;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.logo {
  padding-top: 20px;
  max-width: 200px;
  height: auto;
}

.bannerContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 40px;
}
